import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import lang from "libraries/languages/improve/Languages";
import {
  officeonserver_themeLight,
  officeonserver_themeDark,
} from "libraries/themes/officeonserver/Themes";
import {
  improve_themeLight,
  improve_themeDark,
} from "libraries/themes/improve/Themes";
import {
  maintenance_themeLight,
  maintenance_themeDark,
} from "libraries/themes/maintenance/Themes";

//Pages
const Home = lazy(() => import("views/publicpage/Home"));
const Login = lazy(() => import("views/publicpage/Login"));
const CompanyLogin = lazy(() => import("views/publicpage/CompanyLogin"));
const LoginUserWithoutPassword = lazy(
  () => import("views/publicpage/LoginUserWithoutPassword")
);
const ForgotPassword = lazy(
  () => import("views/publicpage/Forgotpassword.jsx")
);
const RestorePassword = lazy(
  () => import("views/publicpage/RestorePassword.jsx")
);
const ActivateUserAccount = lazy(
  () => import("views/publicpage/activateUserAccount.jsx")
);
const Logout = lazy(() => import("views/webapps/Logout.jsx"));

// APP: IMPROVE
const Dashboard_improve = lazy(
  () => import("views/webapps/improve/dashboard/Dashboard.jsx")
);
const Documents = lazy(
  () => import("views/webapps/improve/documents/Documents.jsx")
);
const Cases = lazy(() => import("views/webapps/improve/cases/Cases.jsx"));
const Flowcharts = lazy(
  () => import("views/webapps/improve/flowcharts/Flowcharts.jsx")
);
const Safety = lazy(() => import("views/webapps/improve/safety/Safety.jsx"));
const Improvement = lazy(
  () => import("views/webapps/improve/improvements/Improvements.jsx")
);
const Projects = lazy(
  () => import("views/webapps/improve/projects/Projects.jsx")
);
const SystemSettings_improve = lazy(
  () => import("views/webapps/improve/systemsettings/SystemSettings.jsx")
);

// APP: MAINTENANCE
const Dashboard_maintenance = lazy(
  () => import("views/webapps/maintenance/dashboard/Dashboard.jsx")
);
const Maintenancework = lazy(
  () => import("views/webapps/maintenance/maintenancework/Maintenancework.jsx")
);
const Facilityobjects = lazy(
  () => import("views/webapps/maintenance/facilityobjects/Facilityobjects.jsx")
);
const Spareparts = lazy(
  () => import("views/webapps/maintenance/spareparts/Spareparts.jsx")
);
const Suppliers = lazy(
  () => import("views/webapps/maintenance/suppliers/Suppliers.jsx")
);
const SystemSettings_maintenance = lazy(
  () => import("views/webapps/maintenance/systemsettings/SystemSettings.jsx")
);

const Myuseraccount = lazy(
  () => import("views/webapps/improve/myuseraccount/Myuseraccount.jsx")
);

const Myactivities = lazy(
  () => import("views/webapps/improve/myactivities/Myactivities.jsx")
);

const WhatsNew = lazy(() => import("views/publicpage/WhatsNew.jsx"));
const ChangeLog = lazy(() => import("views/publicpage/ChangeLog.jsx"));
const NoMatch = lazy(() => import("views/NoMatch.jsx"));
const Sandbox = lazy(() => import("views/sandbox/Sandbox.jsx"));

/** COMPONENT: App */
const App = () => {
  //Get and set active app
  const [activeApp, setActiveApp] = useState("officeonserver"); //Alwas set "officeonserver" -app as default (this affects which theme are used).

  //Get & set: theme
  const [darkMode, setDarkMode] = useState(false);
  const updateDarkmode = (theme) => {
    setDarkMode(theme);
  };

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem("theme"));
    if (storedTheme) {
      setDarkMode(storedTheme);
    } else {
      localStorage.setItem("theme", JSON.stringify(darkMode));
    }
  }, [darkMode]);

  //Get & set: language
  const [selectedLanguage, setSelectedLanguage] = useState("sv");
  const updateLanguage = (lang) => {
    setSelectedLanguage(lang);
    localStorage.setItem("language", JSON.stringify(lang));
  };

  useEffect(() => {
    const storedLanguage = JSON.parse(localStorage.getItem("language"));
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    } else {
      localStorage.setItem("language", JSON.stringify(storedLanguage));
    }
  }, []);

  return (
    <>
      <ThemeProvider
        theme={
          darkMode && activeApp === "officeonserver"
            ? officeonserver_themeDark
            : !darkMode && activeApp === "officeonserver"
              ? officeonserver_themeLight
              : darkMode && activeApp === "improve"
                ? improve_themeDark
                : !darkMode && activeApp === "improve"
                  ? improve_themeLight
                  : darkMode && activeApp === "maintenance"
                    ? maintenance_themeDark
                    : !darkMode && activeApp === "maintenance"
                      ? maintenance_themeLight
                      : officeonserver_themeLight
        }
      >
        <CssBaseline />
        <Suspense
          fallback={
            <div className='container'>
              {lang(selectedLanguage, "Loading...")}
            </div>
          }
        ></Suspense>

        <Routes>
          {/* PUBLIC WEBPAGE */}
          {/* Home */}
          <Route
            index
            path='/'
            element={
              <Home
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                activeApp={activeApp}
              />
            }
          />
          {/* Login */}
          <Route
            path='/login'
            element={
              <Login
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
              />
            }
          />
          {/* Login > QR-code */}
          <Route
            path='/login/:qrCodeModule/:qrCodeObjId'
            element={
              <Login
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
              />
            }
          />
          <Route
            path='/company/:companyName'
            element={
              <CompanyLogin
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
              />
            }
          />
          {/* Login > Without password */}
          <Route
            path='/login-without-password'
            element={
              <LoginUserWithoutPassword
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
              />
            }
          />
          {/* Forgot password */}
          <Route
            path='/forgot-password'
            element={
              <ForgotPassword
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
              />
            }
          />
          {/* Restore password */}
          <Route
            path='/restore-password'
            element={
              <RestorePassword
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
              />
            }
          />
          {/* Activate user account */}
          <Route
            path='/activate-user-account'
            element={
              <ActivateUserAccount
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
              />
            }
          />
          {/* SANDBOX */}
          <Route path='/sandbox' element={<Sandbox />} />
          {/** 
            WEBAPP
           */}
          {/* Dashboard -Improve*/}
          <Route
            path='/improve/dashboard'
            element={
              <Dashboard_improve
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Dashboard -Maintenance*/}
          <Route
            path='/maintenance/dashboard'
            element={
              <Dashboard_maintenance
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Documents */}
          <Route
            path='/improve/documents'
            element={
              <Documents
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Documents > Editor */}
          <Route
            path='/improve/documents/editor/:urlId'
            element={
              <Documents
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Cases */}
          <Route
            path='/improve/cases'
            element={
              <Cases
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Cases > Editor */}
          <Route
            path='/improve/cases/editor/:urlId'
            element={
              <Cases
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Flowcharts */}
          <Route
            path='/improve/flowcharts'
            element={
              <Flowcharts
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Flowchart > Editor */}
          <Route
            path='/improve/flowcharts/editor/:urlId'
            element={
              <Flowcharts
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Safety */}
          <Route
            path='/improve/safety'
            element={
              <Safety
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Safety > view report */}
          <Route
            path='/improve/safety/:urlId' //This will open the view dialog automaticaly if the id is found in any row.
            element={
              <Safety
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Safety > safetuaudits */}
          <Route
            path='/improve/safety/audits'
            element={
              <Safety
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Safety > safetuaudits view report */}
          <Route
            path='/improve/safety/audits/:urlId'
            element={
              <Safety
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Improvement */}
          <Route
            path='/improve/improvements'
            element={
              <Improvement
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Improvement view report*/}
          <Route
            path='/improve/improvements/:urlId'
            element={
              <Improvement
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Projects */}
          <Route
            path='/improve/projects'
            element={
              <Projects
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Projects > Editor */}
          <Route
            path='/improve/projects/editor/:urlId'
            element={
              <Projects
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Maintenancework */}
          <Route
            path='/maintenance/maintenancework'
            element={
              <Maintenancework
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Maintenancework view report */}
          <Route
            path='/maintenance/maintenancework/:urlId' //This will open the view dialog automaticaly if the id is found in any row.'
            element={
              <Maintenancework
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Facility objects */}
          <Route
            path='/maintenance/facilityobjects'
            element={
              <Facilityobjects
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Spareparts */}
          <Route
            path='/maintenance/spareparts'
            element={
              <Spareparts
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Suppliersts */}
          <Route
            path='/maintenance/suppliers'
            element={
              <Suppliers
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* System settings -Improve */}
          <Route
            path='/improve/system-settings'
            element={
              <SystemSettings_improve
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* System settings -Maintenance */}
          <Route
            path='/maintenance/system-settings'
            element={
              <SystemSettings_maintenance
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* My user account */}
          <Route
            path='/improve/my-user-account'
            element={
              <Myuseraccount
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* My activities */}
          <Route
            path='/improve/my-activities'
            element={
              <Myactivities
                activeApp={activeApp}
                setActiveApp={setActiveApp}
                selectedLanguage={selectedLanguage}
                updateDarkmode={updateDarkmode}
                updateLanguage={updateLanguage}
              />
            }
          />
          {/* Logout */}
          <Route
            activeApp={activeApp}
            setActiveApp={setActiveApp}
            path='/logout'
            element={<Logout selectedLanguage={selectedLanguage} />}
          />
          /{/* Whats new in officeOnServer */}
          <Route
            path='/whats-new'
            element={<WhatsNew selectedLanguage={selectedLanguage} />}
          />
          /{/* Changelog (older versions) in officeOnServer */}
          <Route
            path='/changelog'
            element={<ChangeLog selectedLanguage={selectedLanguage} />}
          />
          /{/* Webpage not found -404 */}
          <Route
            path='*'
            element={<NoMatch selectedLanguage={selectedLanguage} />}
          />
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default App;
