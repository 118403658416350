const langObj_sv = [
  {
    /* SWEDISH */

    //0-9
    '404 -Webpage not found': '404 -Webbsidan finns inte',

    //A
    'A week ago': '1 vecka sedan',
    Abort: 'Avbryt',
    'Abort publish request': 'Avbryt förfrågan om publicering',
    'Abort publish request, are you sure':
      'Avbryt förfrågan om bublicering, är du säker',
    'Abort request': 'Avbryt förfrågan',
    Active: 'Aktiv',
    'Active app': 'Aktiv app',
    Accident: 'Olycka',
    Account: 'Konto',
    Activities: 'Aktiviteter',
    Activited: 'Akiverad',
    'Activate account': 'Aktivera kontot',
    'Activate user account': 'Aktivera användarkonto',
    'Account settings': 'Kontoinställningar',
    'Activation mail send': 'Aktiveringsmail skickat',
    'Activation mail was send': 'Aktiveringsmailet är skickat',
    Actual: 'Faktisk',
    Administrator: 'Administratör',
    Add: 'Lägg till',
    'Add calculating data by clicking on the component in the form':
      'Lägg till beräkningsdata genom att klicka på en komponent i formuläret',
    'Add eForm': 'Koppla eFormulär',
    'Add existing documents': 'Lägg till befintliga dokument',
    'Add existing eForm': 'Lägg till befintligt eFormulär',
    'Added recently': 'Tillagd nyligen',
    'Add activity': 'Lägg till aktivitet',
    'Add company logo': 'Lägg till företagslogo',
    'Add column': 'Lägg till ny kolumn',
    'Add eForm template': 'Lägg till eFormulär (mall)',
    'Add new document': 'Lägg till nytt dokument',
    'Add new flowchart': 'Lägg till nytt flödesschema',
    'Add new improvement idea': 'Lägg till ny förbättringsidé',
    'Add new report': 'Lägg till ny rapport',
    'Add new row': 'Lägg till ny rad',
    'Add new task': 'Lägg till ny uppgift',
    'Add new project': 'Lägg till nytt projekt',
    'Add new user': 'Lägg till ny användare',
    'Add new category': 'Lägg till ny kategori',
    'Add new sub category': 'Lägg till ny underkategori',
    'Add new department': 'Lägg till ny avdelning',
    'Add new sub department': 'Lägg till ny undergrupp',
    'Add new option': 'Lägg till nytt val',
    'Add node': 'Lägg till figur',
    'Add more decimals': 'Öka antalet decimaler',
    'Add user image': 'Lägg till profilbild',
    'Add new page': 'Lägg till ny sida',
    'Add task': 'Lägg till uppgift',
    'Add sub task': 'Lägg till underliggande uppgift',
    'Add who should be responsible for the case':
      'Lägg till ansvarig för ärendet',
    'Add who should be responsible for the improvement':
      'Lägg till ansvarig för genomförandet av förbättringen',
    'Add who should be responsible for the safety issue':
      'Lägg till ansvarig för det här ärendet',
    'Add who should be responsible for the safety audit':
      'Lägg till ansvarig för skyddsronden',
    'Add who should be project manager for the project':
      'Lägg till projektledare för projektet',
    'Add who should be responsible for the task':
      'Lägg till ansvarig för uppgiften',
    'Add swimlane': 'Lägg till simbana',
    All: 'Alla',
    'All(2)': 'Allt',
    'All documents': 'Alla dokument',
    'All flowcharts': 'Alla flödesscheman',
    'All projects': 'Alla projekt',
    'All registered cases': 'Alla registrerade ärenden',
    'A repeated password is required': 'Du måste upprepa lösenordet',
    'An activation mail will be send to':
      'Ett aktiveringsmail kommer att skickas till',
    Archive: 'Arkiv',
    'Arrows & lines': 'Pilar & linjer',
    'An email message has been send to ':
      'Ett e-postmeddelande har skickats till ',
    ' with further instructions': ' med vidare instruktioner',
    August: 'Augusti',
    Aug: 'Aug',
    'Audit area': 'Revisionsområde',
    'Audit documentation': 'Revisionsunderlag',
    April: 'April',
    Apr: 'Apr',
    Apps: 'Appar',
    'Approved by': 'Godkänd av',
    'Approve & publish': 'Godkänn & publicera',
    'Approval & publishing request': 'Förfrågan om godkännande och publicering',
    'Align horizontally': 'Linjera horisontellt',
    'Align vertically': 'Linjera vertikalt',
    Auto: 'Auto',
    Awaits: 'Inväntar',

    //B
    Back: 'Tillbaka',
    'Be sure to save the questions you answered before completing the safety audit. You do not have to answer all the questions at once, but can resume the security round at a later time':
      'Var noga med att spara de frågor du besvarat innan du avslutar skyddsronden. Du behöver inte svara på alla frågorna på en gång utan kan återuppta skyddsronden vid ett senare tillfälle',
    'Big effort': 'Stor insats',
    'Big effect': 'Stor effekt',
    'Big risk': 'Stor risk',
    'Big consequence': 'Stor konsekvens',
    Budget: 'Budget',
    By: 'Av',
    'Body text': 'Brödtext',
    Bottom: 'Under',
    'Bottom(2)': 'Undersida',

    //C
    Case: 'Ärende',
    'Case is now private': 'Ärendet är nu privat',
    'Case number': 'Ärendenr.',
    'Case was deleted': 'Ärendet togs bort',
    Cases: 'Ärenden',
    'Case was added': 'Ärendet lades till',
    'Cases where you are responsible': 'Ärenden där du är ansvarig',
    'Cases shared with you': 'Ärenden delade med dig',
    Category: 'Kategori',
    Categories: 'Kategorier',
    "Can't get inserted id": 'Hittar inte senast tillagda id',
    'Case was moved': 'Ärendet flyttades',
    'Case was saved': 'Ärendet sparades',
    'Categories was saved': 'Kategorierna sparades',
    'Capacity for projects': 'Kapacitet för projekt',
    Checked: 'Markerad',
    Chemicals: 'Kemikalier',
    Change: 'Ändra',
    'Changed by': 'Ändrad av',
    'Change password': 'Ändra lösenord',
    'Change company image': 'Byt företagsbild',
    'Change user image': 'Byt användarbild',
    'Change to complete': 'Ändra till genomförd',
    'Change to complete, are you sure': 'Ändra till genomförd, helt säker',
    'Change to uncomplete': 'Ändra till ej genomförd',
    'Change to uncomplete, are you sure': 'Ändra till ej genomförd, helt säker',
    'Change log': 'Ändringslogg',
    'Clear all notifiactions': 'Rensa alla notiser',
    'Click on icon to upload file': 'Klicka på ikonen för att ladda upp filen',
    Checkbox: 'Checkruta',
    City: 'Ort',
    'Click on image to upload another file':
      'Klicka på bilden för att ladda upp en annan fil',
    'Click on icon to upload image':
      'Klicka på ikonen för att ladda upp en bild',
    'Click on the button to login': 'Klicka på knappen för att logga in',
    'Click on the button below to activate your account. This session is valid for 3 minutes':
      'Klicka på knappen nedan för att aktivera ditt användarkonto. Den här sessionen är giltlig i 3 minuter',
    'Consequence factor': 'Konsekvensfaktor',
    'Cost follow-up': 'Kostnadsuppföljning',
    'Cost allocation': 'Kostnadsfördelning',
    Column: 'Kolumn',
    'Continued on the next page': 'Fortsättning på nästa sida',
    'Continue by clicking the button with the status you want to move the case with':
      'Fortsätt genom att klicka på den knapp med den status som du vill flytta ärendet med',
    'Continue by clicking the button with the status you want to move the improvement with':
      'Fortsätt genom att klicka på den knapp med den status som du vill flytta förbättringen med',
    "Continue by clicking the button 'Completed' to move the safety issue report":
      "Fortsätt genom att klicka knappen 'Genomförd' för att flytta säkerhetsärendet",
    Close: 'Stäng',
    Comments: 'Kommentar',
    Company: 'Företag',
    'Company name': 'Företagsnamn',
    'Company info was updated': 'Företagsinformationen uppdaterades',
    'Compressed file': 'Komprimerad fil',
    Completed: 'Genomförd',
    'Completed(2)': 'Genomförda',
    Component: 'Komponent',
    Continue: 'Fortsätt',
    'Continue(2)': 'Återuppta',
    Condition: 'Villkor',
    'Create a link to another flowchart, document or a custom link below':
      'Skapa en länk till ett annat flödesschema, dokument eller en egen länk nedan',
    Created: 'Skapad',
    'Created by': 'Skapad av',
    'Create calculation': 'Skapa beräkning',
    'Create new': 'Skapa ny',
    'Create new audit': 'Skapa ny revision',
    'Create new e-document': 'Skapa nytt e-dokument',
    'Create new safety audit': 'Skapa ny skyddsrond',
    'Create new project': 'Skapa nytt projekt',
    'Create one': 'Skapa ett',
    'Create link': 'Skapa länk',

    //D
    'Data type': 'Datatyp',
    Date: 'Datum',
    Dashed: 'Streckad',
    'Date of the occurrence': 'Datum för händelsen',
    'days ago': 'dagar sedan',
    Darkmode: 'Mörkt läge',
    Dashboard: 'Översikt',
    Days: 'Dagar',
    December: 'December',
    Dec: 'Dec',
    Decline: 'Avböj',
    Declined: 'Avböjd',
    'Declined by': 'Avböjd av',
    'Decline request': 'Avböj förfrågan',
    'Declined requests': 'Avböjda förfrågningar',
    'Decline request, are you sure': 'Avböj förfrågan, är du säker',
    'Default value': 'Förinställt värde',
    Description: 'Beskrivning',
    'Describe the course of events': 'Beskriv händeleseförloppet',
    'Description of the event': 'Beskrivning av händeleseförloppet',
    Department: 'Avdelning',
    Delete: 'Ta bort',
    'Delete case': 'Ta bort',
    'Delete case, are you sure': 'Ta bort ärende, är du säker',
    'Delete document': 'Ta bort dokumentet',
    'Delete document, are you sure': 'Ta bort dokumentet, är du säker',
    'Delete file': 'Ta bort fil',
    'Delete file, are you sure': 'Ta bort fil, är du säker',
    'Delete flowchart': 'Ta bort flödesschemat',
    'Delete flowchart, are you sure': 'Ta bort flödesschemat, är du säker',
    'Delete report': 'Ta bort rapporten',
    'Delete report, are you sure': 'Ta bort rapporten, är du säker',
    'Delete improvement': 'Ta bort förbättringen',
    'Delete improvement, are you sure': 'Ta bort förbättringen, är du säker',
    'Delete task': 'Ta bort uppgift',
    'Delete task, are you sure': 'Ta bort uppgift, är du säker',
    'Delete project phase': 'Ta bort projektfas',
    'Delete project phase, are you sure': 'Ta bort projektfas, är du säker',
    'Delete sub task': 'Ta bort uppgift',
    'Delete sub task, are you sure': 'Ta bort uppgift, är du säker',
    'Delete project': 'Ta bort projektet',
    'Delete project, are you sure': 'Ta bort projektet, är du säker',
    'Delete user': 'Ta bort användaren',
    'Delete user, are you sure': 'Ta bort användaren, är du helt säker',
    'Delete department': 'Ta bort avdelning',
    'Delete department, are you sure': 'Ta bort avdelningen, är du helt säker',
    'Delete category': 'Ta bort kategori',
    'Delete category, are you sure': 'Ta bort kategorin, är du helt säker',
    'Delete selected': 'Ta bort markering',
    'Delete selected, are you sure': 'Ta bort markering, är du säker',
    'Delete selected documents': 'Ta bort markerade dokument',
    'Delete selected documents, are you sure':
      'Ta bort markerade dokument, är du säker',
    'Delete selected cases': 'Ta bort markerade ärenden',
    'Delete selected cases, are you sure':
      'Ta bort markerade ärenden, är du säker',
    'Delete selected flowcharts': 'Ta bort markerade flödesscheman',
    'Delete selected flowcharts, are you sure':
      'Ta bort markerade flödesscheman, är du säker',
    'Delete selected reports': 'Ta bort markerade rapporter',
    'Delete selected reports, are you sure':
      'Ta bort markerade rapporter, är du säker',
    'Delete safety audit': 'Ta bort skyddsrond',
    'Delete selected safety audits': 'Ta bort markerade skyddsronder',
    'Delete safety audit, are you sure': 'Ta bort skyddsrond, är du säker',
    'Delete selected safety audits, are you sure':
      'Ta bort markerade skyddsronder, är du säker',
    'Delete selected improvements': 'Ta bort markerade förbättringar',
    'Delete selected improvements, are you sure':
      'Ta bort markerade förbättringar, är du säker',
    'Delete selected projects': 'Ta bort markerade projekt',
    'Delete selected projects, are you sure':
      'Ta bort markerade projekt, är du säker',
    'Delete selected users': 'Ta bort markerade användare',
    'Delete selected users, are you sure':
      'Ta bort markerade användare, är du säker',
    'Delete page': 'Ta bort sidan',
    'This type of case is private and will only be visible to you and those you share the case with':
      'Den här typen av ärende är privat och kommer enbart att vara synligt för dig och de du delar ärendet med',
    Deviation: 'Avvikelse',
    Deviations: 'Avvikelser',
    'Deviations require a comment': 'Avvikelser kräver en kommentar',
    'Digitally signed': 'Digitalt signerad',
    Document: 'Dokument',
    Documents: 'Dokument',
    'Document already exists': 'Dokumentet finns redan',
    'Document categories': 'Dokument kategorier',
    'Document name': 'Dokumentnamn',
    'Document was added': 'Dokumentet lades till',
    'Document was deleted': 'Dokumentet togs bort',
    'Document was removed': 'Dokumentet kopplades bort',
    'Document was published': 'Dokumentet publicerades',
    'Document was updated': 'Dokumentet uppdaterades',
    'Document was saved': 'Dokumentet sparades',
    'Document was signed': 'Dokumentet signerades',
    'Download as JSON': 'Ladda ner som JSON',
    'Document type': 'Dokumenttyp',
    'Document publish requests': 'Förfrågan om godkänande av dokument',
    'Document no. is required': 'Dokumentnr. måste anges',
    'Document number': 'Dokumentnr.',
    'Doc.no.': 'Dok.nr.',
    "Don't save": 'Spara inte',
    'Drugs & abuse': 'Droger & missbruk',
    //E
    'E-document': 'E-dokument',
    Effort: 'Insats',
    Effect: 'Effekt',
    Edit: 'Ändra',
    Editor: 'Editor',
    'Edit case': 'Redigera ärende',
    'Edit document': 'Ändra dokument',
    'Edit flowchart': 'Ändra flödesschema',
    'Edit improvement': 'Ändra förbättringen',
    'Edit report': 'Ändra rapporten',
    'Edit safety audit': 'Ändra skyddsrond',
    'Edit task': 'Ändra uppgift',
    'Edit user': 'Ändra användare',
    'Edit company color': 'Ändra företagsfärg',
    'Edit profile color': 'Ändra profilfärg',
    'Edit project': 'Ändra projekt',
    'Edit project phase': 'Ändra projektfas',
    'Edit report': 'Ändra rapport',
    'Editmode is not enabled': 'Redigeringsläget är inte på',
    Ergonomics: 'Ergonomi',
    'Electrical safety': 'Elsäkerhet',
    Email: 'E-post',
    'Email address': 'E-post adress',
    'Email: automatic reminders': 'E-post: automatiska påminnelser',
    'Email is required': 'Du har inte fyllt i någon e-postadress',
    'Email: Send notifications': 'E-post: Skicka notiser',
    End: 'Slut',
    'End date': 'Slutdatum',
    English: 'Engelska',
    'Excel file': 'Excel fil',
    'Existing documents': 'Befintliga dokument',
    'External cost': 'Extern kostnad',

    //F
    'Failed to save passkey': 'Registreringen misslyckades',
    February: 'Februari',
    Feb: 'Feb',
    Files: 'Filer',
    'Files & documents': 'Filer & dokument',
    'File size is larger than allowed': 'Filstorleken är större än tillåtet',
    'File type': 'Filtyp',
    'File name': 'Filnamn',
    'File was added': 'Filen lades till',
    'File was uploaded': 'Filen laddades upp',
    'Filename already exists': 'Filnamnet finns redan',
    Filter: 'Filter',
    'Fire protection': 'Brandskydd',
    'First name': 'Förnamn',
    'First name is required': 'Förnamn måste anges',
    'First aid and medical care': 'Första hjälpen & sjukvård',
    Flowcharts: 'Flödesscheman',
    'Flowchart publish requests': 'Förfrågan om godkänande av flödesschema',
    'Flowchart name': 'Namn på flödesschema',
    Flowchart: 'Flödesschema',
    'Flowchart was added': 'Flödesschemat lades till',
    'Flowchart was published': 'Flödesschemat publicerades',
    'Flowchart was saved': 'Flödesschemat sparades',
    'Flowchart was deleted': 'Flödesschemat togs bort',
    'Flcrt.No.': 'Flsch.Nr.',
    'Focus area': 'Fokusområde',
    'Forgot password': 'Glömt lösenord',
    Friday: 'Fredag',
    Fri: 'Fre',
    Form: 'Formulär',

    //G
    'Gantt chart': 'Ganttschema',
    GBP: 'SEK',
    'Go to login page': 'Gå till inloggning',
    'Go to regular login page': 'Gå till vanlig inloggning',
    //H
    'h/week': 'tim/vecka',
    Heading: 'Rubrik',
    Headline: 'Rubrik',
    Hi: 'Hej',
    Home: 'Hem',
    'Hours of workload': 'Timmars beläggning',
    'Hour rate': 'Timkostnad',

    //I
    'I forgot my password': 'Jag har glömt mitt lösenord',
    'If you make the case private, it will only be visible to you and those you share the case with':
      'Om du gör ärendet privat kommer det enbart att vara synligt för dig och de du delar ärendet med',
    "It seems you don't have permission to enter this page":
      'Det verkar som om du inte har rättigheter för att visa den här sidan',
    Idle: 'Vilande',
    Inactive: 'Inaktiv',
    Incident: 'Tillbud',
    'Internal cost': 'Intern kostnad',
    Integer: 'Tal',
    'Invalid temporary key': 'Felaktig temporär nyckel',
    'Invalid password': 'Felaktigt lösenord',
    'Invalid session': 'Ogiltlig session',
    'Invalid filename': 'Felaktigt filnamn',
    'Invalid Image type': 'Ogiltligt bildformat',
    'Invalid file type': 'Ogiltligt filformat',
    'Invite more participant': 'Bjud in fler deltagare',
    Input: 'Textruta',
    'Image was updated': 'Bilden uppdaterades',
    'Image was removed': 'Bilden togs bort',
    'Implemented countermeasure': 'Genomförd åtgärd',
    'Image file': 'Bild fil',
    Improvement: 'Förbättring',
    Improvements: 'Förbättringar',
    'Improvement area': 'Förbättringsområde',
    'Improvement title': 'Rubrik',
    'Improvement was added': 'Förbättingsidén lades till',
    'Improvement was deleted': 'Förbättingsidén togs bort',
    'Improvement was saved': 'Förbättingen sparades',
    'Improvement was moved': 'Förbättringen flyttades',
    'Improvements where you are responsible':
      'Förbättringar som du ansvarar för',
    'Improvement headline': 'Förbättringsrubrik',
    'Improvement number': 'Förbättringsnummer',
    'ISO 45001': 'ISO 45001',
    //J
    January: 'Januari',
    Jan: 'Jan',
    June: 'Juni',
    Jun: 'Jun',
    July: 'Juli',
    Jul: 'Jul',
    //L
    Label: 'Etikett',
    Language: 'Språk',
    'Last modified documents': 'Senast ändrade dokument',
    Layout: 'Layout',
    'Last name': 'Efternamn',
    'Last name is required': 'Efternamn måste anges',
    'Last login': 'Senast inloggad',
    'Last modified': 'Senast ändrad',
    'Last modified by': 'Senast ändrad av',
    'Learn more': 'Ta reda på mer',
    Left: 'Vänster',
    'Left(2)': 'Vänstersida',
    'Left menu: New/updated items': 'Vänstermeny: Nya/uppdaterade objekt',
    'Link to': 'Länka till',
    'Loading...': 'Laddar...',
    'Lock the document after sign off':
      'Lås dokumentet för redigering efter signering',
    Login: 'Logga in',
    'Login again': 'Logga in igen',
    'Login without password': 'Logga in utan lösenord',
    'Log out': 'Logga ut',
    'Logged in today': 'Loggade in idag',
    'Logged yesterday': 'Loggade in igår',
    'Logged in': 'Loggade in för',
    'Logged in a week ago': 'Loggade in för en vecka sedan',
    'Logged in over a year ago': 'Loggade in för över ett år sedan',
    'Long term counter measure': 'Långsiktig åtgärd',
    //M
    'Machine safety': 'Maskinsäkerhet',
    'Make active': 'Gör aktiv',
    'Make idle': 'Gör vilande',
    'Make this project active, are you sure':
      'Gör detta projekt aktivt, är du säker',
    'Make this project idle, are you sure':
      'Gör detta projekt vilande, är du säker',
    'Make to form': 'Gör till formulär',
    'Make private': 'Gör privat',
    'Make public': 'Gör publik',
    May: 'Maj',
    March: 'Mars',
    Mar: 'Mar',
    Margins: 'Marginaler',
    Minimize: 'Minimera',
    Maximize: 'Maximera',
    Media: 'Media',
    Message: 'Meddelande',
    Monday: 'Måndag',
    Mon: 'Mån',
    Month: 'Månad',
    'Month(2)': 'Månader',
    'month ago': 'månader sedan',
    Modified: 'Ändrad',
    'Modified by': 'Ändrad av',
    Modules: 'Moduler',
    'Must be at least 2 characters': 'Kräver minst 2 tecken',
    'Must be at least 6 characters': 'Måste vara minst 6 tecken',
    'Must not exceed 20 characters': 'Får inte överskrida 20 tecken',
    'Must not exceed 100 characters': 'Får inte överskrida 100 tecken',
    'More info': 'Mer info',
    Move: 'Flytta',
    'Move as completed': 'Flytta som genomförd',
    'Move as idle': 'Flytta som vilande',
    'Move as declined': 'Flytta som avslagen',

    'Move to planned': 'Flytta till planerade',
    "Move this safety audit to status 'planned', are you sure":
      "Flytta den här skyddsronden till status 'planerade', är du säker",
    'Move to ongoing': 'Flytta till pågående',
    "Move this safety audit to status 'ongoing', are you sure":
      "Flytta den här skyddsronden till status 'pågående', är du säker",
    "Move this improvement to status 'ongoing', are you sure":
      "Flytta den här förbättringen till status 'pågående', är du säker",
    'Move to archived': 'Flytta till arkiverad',
    'Move to completed': 'Flytta till genomförda',
    "Move this case to status 'archived', are you sure":
      "Flytta det här ärendet till status 'arkiverad', är du säker",
    "Move this improvement to status 'archived', are you sure":
      "Flytta den här förbättringen till status 'arkiverad', är du säker",
    "Move this safety issue to status 'ongoing', are you sure":
      "Flytta det här säkerhetsärendet till status 'pågående', är du säker",
    'Move to completed': 'Flytta till genomförda',
    "Move this safety issue to status 'completed', are you sure":
      "Flytta det här säkerhetsärendet till status 'genomförda', är du säker",
    "Move this project to status 'planned', are you sure":
      "Flytta det här projektet till status 'planerade', är du säker",
    "Move this project to status 'ongoing', are you sure":
      "Flytta det här projektet till status 'pågående', är du säker",
    "Move this project to status 'archived', are you sure":
      "Flytta det här projektet till status 'arkiverad', är du säker",
    "Move this project to status 'completed', are you sure":
      "Flytta det här projeket till status 'genomförd', är du säker",
    'My activities': 'Mina aktiviteter',
    'My cases': 'Mina ärenden',
    'My user account': 'Mitt användarkonto',

    //N
    Name: 'Namn',
    'Never logged in': 'Aldrig inloggad',
    New: 'Ny',
    Next: 'Nästa',
    'New bodytext': 'Ny brödtext',
    'New checkbox': 'Ny kryssruta',
    'New datepicker': 'Ny datumväljare',
    'New input': 'Nytt textfält',
    'New headline': 'Ny rubrik',
    'New password': 'Nytt lösenord',
    'New selectbox': 'Ny listmeny',
    'New item': 'Ny figur',
    'No remark': 'Ingen anmärkning',
    'No activities': 'Inga aktiviteter',
    Number: 'Nummer',
    'No rows': 'Inga rader',
    'No suggestion': 'Inga förslag',
    'No passkey found': 'Ingen passkey funnen',
    'No.': 'Nr.',
    'No one': 'Ingen',
    'No of days to notify objects as new/updated':
      'Antal dagar som objekts skall flaggas som nya/uppdaterade',
    'Non-numeric': 'Icke-numerisk',
    'Number of days that the reminder should be sent before the planned date of the event':
      'Antal dagar som påminnelsen skall skickas innan planerat datum för händelsen',
    Numeric: 'Numerisk',
    Notifiaction: 'Notis',
    Notifiactions: 'Notiser',
    Nodes: 'Figurer',
    'Not a valid email address': 'Felaktigt format',
    'Not approved': 'Ej godkänd',
    'Not reported': 'Ej raporterat',
    November: 'November',
    Nov: 'Nov',
    Normal: 'Normal',
    'New idea': 'Ny idé',
    'New ideas': 'Nya ideér',
    'New ideas': 'Nya ideér',
    'New reports': 'Nya rapporter',
    'New project phase': 'Ny projektfas',

    //O
    Of: 'Av',
    Observation: 'Observation',
    Observations: 'Observationer',
    October: 'Oktober',
    Oct: 'Okt',
    'Occupancy in hours': 'Beläggning i timmar',
    Occurred: 'Inträffade',
    Ok: 'Ok',
    'on the emaildress': 'på emailaddress',
    Ongoing: 'Pågående',
    'Oops!': 'Oops!',
    "Oops! User dosen't exist": 'Oops! Användaren finns inte',
    'Oops! something went wrong': 'Oops! något blev fel',
    "Oops! You don't have permission to do this":
      'Oops! Du har inte rättigheter för att utföra det här',
    Or: 'Eller',
    Order: 'Ordning',
    Organization: 'Organisation',
    'Organization was saved': 'Organisationen sparades',
    Other: 'Övrigt',
    'Other apps': 'Andra appar',
    'Other settings': 'Andra inställningar',
    'Over a year ago': 'Över ett år sedan',
    Overview: 'Översikt',
    'Open case': 'Öppna ärende',
    'Open e-document': 'Öppna e-dokument',
    'Open flowchart': 'Öppna flödesschema',
    'Open project': 'Öppna projekt',
    Options: 'Val',

    //P
    'Pallet rack, forklift & gantry lifting': 'Pallställ truck & travers',
    Password: 'Lösenord',
    'Password is required': 'Du måste ange ett lösenord',
    'Passkey validated and accepted': 'Passkey accepterad',
    "Passkey didn't validate": 'Passkey validerades inte',
    'Passkey saved successfully': 'Passkey har lagts till',
    'Private mode': 'Privat läge',
    'Problem area': 'Problemområde',
    Progress: 'Progress',
    Project: 'Projekt',
    Projects: 'Projekt',
    'Project is active': 'Projektet är nu aktivt',
    'Project is idle': 'Projektet är nu vilande',
    'Project is not saved, save first': 'Projektet är inte sparat, spara först',
    'Project manager': 'Projektledare',
    'Project number': 'Projektnummer',
    'Project title': 'Rubrik',
    'Project tasks where you are responsible':
      'Projektuppgifter som du ansvarar för',
    'Project was created and added': 'Projektet skapades och lades till',
    'Project was saved': 'Projektet sparades',
    'Project was moved': 'Projektet flyttades',
    'Projects where you are project manager': 'Projekt där du är projektledare',
    'Projects teams in which you are part of': 'Projektgrupper som du ingår i',
    'Project phase was added': 'Projektfasen lades till',
    'Password must be at least 6 characters':
      'Lösenordet måste bestå av minst 6 tecken',
    'Must be a number': 'Kan bara vara siffror',
    'Passwords must match': 'Lösenorden matchar inte',
    'Password was updated': 'Lösenordet uppdaterades',
    Page: 'Sida',
    'Page added': 'Sida tillagd',
    'Page was removed': 'Sidan togs bort',
    'PDF file': 'PDF fil',
    Permissions: 'Rättigheter',
    'Personal Protective Equipment': 'Personlig skyddsutrustning',
    'Place holder': 'Hjälptext',
    Planning: 'Planering',
    Planned: 'Planerade',
    'Planned(2)': 'Planerad',
    'Please choose a planned date for the audit':
      'Välj ett planerat datum för skyddsronden',
    'Please choose a start date': 'Välj ett startdatum',
    'Please choose a end date': 'Välj ett slutdatum',
    'Please close the webbrowser': 'Stäng din webläsare',
    'Please choose what type of document to add':
      'Välj vilken typ av dokument du vill lägga till',
    'Please choose if you want to add a file or document':
      'Välj om du vill lägga till en fil eller ett dokument',
    'Please enter a strong password for your user account below to activate your user account. To login, use the email address which you recived the activation message as your username':
      'Ange ett starkt lösenord för ditt användarkonto nedan för att aktivera ditt användarkonto. För att logga in, använd e-postadressen som du tog emot aktiveringsmeddelandet på som ditt användarnamn',
    'Please wait': 'Vänta',
    'Please indicate how this improvement should be prioritized by clicking in the matrix':
      'Ange hur förbättringen skall prioriteras genom att klicka i matrisen',
    'Please indicate how this Safety issue should be prioritized by clicking in the matrix':
      'Ange hur detta säkerhetsärende ska prioriteras genom att klicka i matrisen',
    Print: 'Skriv ut',
    'Primary app was updated': 'Förvald app uppdaterades',
    Prioritize: 'Prioritering',
    'Prio.': 'Prio.',
    'Prio. points': 'Prio. tal',
    Prioritization: 'Prioritering',
    'Project was added': 'Projektet lades till',
    'Project was deleted': 'Projektet togs bort',
    Profile: 'Profil',
    Process: 'Process',
    Publish: 'Publicera',
    'Publish document': 'Publicera dokument',
    'Publish document, are you sure': 'Publicera dokumentet, är du säker',
    'Publish selected documents': 'Publicera markerade dokument',
    'Publish selected documents, are you sure':
      'Publicera markerade dokumentet, är du säker',
    'Publish flowchart': 'Publicera flödesschema',
    'Publish flowchart, are you sure': 'Publicera flödesschema, är du säker',
    'Publish selected flowcharts': 'Publicera markerade flödesscheman',
    'Publish selected flowcharts, are you sure':
      'Publicera markerade flödesscheman, är du säker',

    'Published & approved': 'Publicerad & godkänd',
    'Published recently': 'Publicerades nyligen',
    'Post(2)': 'Bokför',
    'Post external cost': 'Bokför extern kostnad',
    'Powerpoint file': 'Powerpoint fil',
    Public: 'Publik',

    //Q
    Question: 'Fråga',
    Questions: 'Frågor',
    'Questions answered': 'Frågor besvarade',

    //R
    Registered: 'Registrerad',
    'Register passkey': 'Registrera passkey',
    'Registred since': 'Registrerad sedan',
    'Registered recently': 'Nyligen registrerad',
    'Register new case': 'Registrera nytt ärende',
    'Regular user': 'Användare',
    Remove: 'Ta bort',
    'Remove decimals': 'Minska antalet decimaler',
    'Remove image': 'Ta bort bild',
    'Remove link': 'Ta bort länk',
    'Remove link, are you sure': 'Ta bort länk, är du säker',
    Report: 'Rapport',
    'Report number': 'Rapportnummer',
    'Report was deleted': 'Rapporten togs bort',
    'Report was added': 'Rapporten lades till',
    'Report was saved': 'Rapporten sparades',
    'Report was moved': 'Rapporten flyttades',
    'Request send to': 'Förfrågan skickad till',
    'Request send from': 'Förfrågan skickad från',
    'Request was declined': 'Förfrågan har avböjts',
    'Send activation mail': 'Skicka aktiveringsmail',
    'Reorder by start date': 'Omsortera efter startdatum',
    'Repeat password': 'Upprepa lösenordet',
    Restore: 'Återställ',
    Result: 'Resultat',
    Responsible: 'Ansvarig',
    'Responsible for the safety audit': 'Ansvarig för skyddsronden',
    'Responsible missing': 'Ansvarig saknas',
    'Restore password': 'Återställ lösenord',
    'Resource planning': 'Resursplanering',
    Requests: 'Förfrågningar',
    Required: 'Tvingande',
    'Request was aborted': 'Förfrågan togs bort',
    'Request was send to user': 'Förfrågan har skickats',
    'Risk assessment': 'Riskbedömning',
    'Risk factor': 'Riskfaktor',
    Right: 'Höger',
    'Right(2)': 'Högersida',
    'Root cause': 'Grundorsak',
    Row: 'Rad',

    //S
    Safety: 'Hälsa & säkerhet',
    'Safety issue reports': 'Tillbud & olycksrapporter',
    'Safety issue report': 'Tillbud & olycksrapport',
    'Safety audits': 'Skyddsronder',
    'Safety audit': 'Skyddsrond',
    'Safety audit started': 'Skyddsronden startades',
    'Safety audit teams you are part of': 'Skyddsronder som du är deltagare i',
    'Safety audit was deleted': 'Skyddsronden togs bort',
    'Safety audit was created and added':
      'Skyddsronden skapades och lades till',
    'Safety audit was saved': 'Skyddsronden sparades',
    'Safety audit was moved': 'Skyddsronden flyttades',
    'Safety audits where you are responsible':
      'Skyddsronder som du är ansvarig för',
    'Safety audit started and moved to ongong safety audits':
      'Skyddsronden startades och flyttades till pågående ronder',
    'Safety & health': 'Hälsa & säkerhet',
    Saturday: 'Lördag',
    Sat: 'Lör',
    Save: 'Spara',
    'Save project': 'Spara projekt',
    'Send notification of assigned responsibility by email to users':
      'Skicka e-post om tilldelat ansvar till användare',
    'Send notification to added team member by email':
      'Skicka e-post till användare som läggs till i team',
    'Send publish request': 'Skicka förfrågan om publicering',
    'Save as completed': 'Spara som genomförd',
    'Saved recently': 'Sparades nyligen',
    'Send a request to ask for approval and publishment of this document from someone else in your organization':
      'Skicka en förfågan om godkännande och publicering av det här dokumentet till någon annan i din organisation',
    'Send a request to ask for approval and publishment of this flowchart from someone else in your organization':
      'Skicka en förfågan om godkännande och publicering av det här flödesschemat till någon annan i din organisation',
    'Short term counter measure': 'Kortsiktig åtgärd',
    Size: 'Storlek',
    Settings: 'Inställningar',
    'Send login link': 'Skicka länk för inloggning',
    Search: 'Sök',
    'Search among project team members': 'Sök bland projektmedlemmar',
    'Search user': 'Sök användare',
    'Search everywhere': 'Sök överallt',
    September: 'September',
    Sep: 'Sep',
    'Session has expired': 'Sessionen är inte längre giltlig',
    Select: 'Vallista',
    'Select date': 'Välj datum',
    'select which users you want to share the case with':
      'Välj vilka användare du vill dela ärendet med',
    'Selected documents was deleted': 'Markerade dokument togs bort',
    'Selected cases was deleted': 'Markerade ärenden togs bort',
    'Selected rows was deleted': 'Markerade rader togs bort',
    'Selected files was deleted': 'Markerade filer togs bort',
    'Selected documents was published': 'Markerade dokument publicerades',
    'Selected flowcharts was deleted': 'Markerade flödesscheman togs bort',
    'Selected flowcharts was published': 'Markerade flödesscheman publicerades',
    'Selected reports was deleted': 'Markerade rapporter togs bort',
    'Selected improvements was deleted': 'Markerade förbättringar togs bort',
    'Selected projects was deleted': 'Markerade projekt togs bort',
    'Selected users was deleted': 'Markerade användare togs bort',
    'Set primary app on login': 'Förvald app vid inloggning',
    Swedish: 'Svenska',
    Send: 'Skicka',
    'Send to': 'Skickad till',
    'Send from': 'Skickad från',
    'Suggested responsible': 'Föreslagen ansvarig',
    'Suggestions for improvement': 'Förslag på förbättring',
    'Super user': 'Superuser',
    'Sound file': 'Ljud fil',
    Share: 'Dela',
    'Share case': 'Dela ärende',
    'Shared by': 'Delad av',
    'Shared with': 'Delad med',
    'Sharing was updated': 'Delningen uppdaterades',
    'Show archived cases': 'Visa arkiverade ärenden',
    'Show archived cases only': 'Visa enbart arkiverade ärenden',
    'Show completed tasks': 'Visa färdiga uppgifter',
    'Show result': 'Visa resultat',
    'Show inactive users': 'Visa inaktiva användare',
    'Show inactive users only': 'Visa enbart inaktiva användare',
    'Show my activities': 'Visa mina aktiviteter',
    'Show processed documents': 'Visa låsta dokument',
    'Show processed documents only': 'Visa enbart låsta dokument',
    'Show processed flowcharts': 'Visa låsta flödesscheman',
    'Show processed flowcharts only': 'Visa enbart låsta flödesscheman',
    Signed: 'Signerad',
    Signature: 'Signatur',
    'Sign off': 'Signera',
    'Small effort': 'Liten insats',
    'Small effect': 'Liten effekt',
    'Small risk': 'Liten risk',
    'Small consequence': 'Liten konsekvens',
    Solid: 'Solid',
    Start: 'Start',
    'Start date': 'Startdatum',
    "Start date can't occour after end date":
      'Startdatum kan inte inträffa efter slutdatum',
    'Start(2)': 'Början',
    'Start(3)': 'Börja',
    Status: 'Status',
    'Stress & psychosocial work environment': 'Stress & psykosocialarbetsmiljö',
    String: 'Text',
    Storage: 'Lagring',
    Sort: 'Sortera',
    'Street address': 'Gatuadress',
    Subheading: 'Underrubrik',
    Sunday: 'Söndag',
    Sun: 'Sön',
    'System settings': 'Systeminställningar',
    'System settings was saved': 'Systeminställningarna sparades',
    Swimlane: 'Simbana',
    //T
    Task: 'Uppgift',
    Tasks: 'Uppgifter',
    'Task was added': 'Uppgiften lades till',
    'Task was deleted': 'Uppgiften togs bort',
    'Task was updated': 'Uppgiften uppdaterades',
    Team: 'Team',
    Text: 'Text',
    'Safety audit teams you are part of': 'Skyddsronder som du är deltagare i',
    'Team was saved': 'Teamet sparades',
    Telephone: 'Telefon',
    'Text document': 'Textdokument',
    'Text file': 'Text fil',
    'The audit has not been started yet': 'Skyddsronden har inte startats än',
    'The file was deleted': 'Filen togs bort',
    'The password has been restored successfully': 'Lösenordet är uppdaterat',
    'The file is empty': 'Filen är tom',
    'The operation ether timeout or aborted':
      'Operationen tog för lång tid eller avbröts',
    'There are a total of x activities that concern you':
      'Det finns totalt x aktiviteter som berör dig',
    'There are no activities that concern you':
      'Det finns inga aktiviteter som berör dig',
    'There is no responsible picked. Choose a responsible first':
      'Det finns ingen ansvarig vald. Välj en ansvarig först',
    'There is no project manager picked. Choose one first':
      'Det finns ingen projektledare vald. Välj en först',
    'There is no tasks yet': 'Det finns inga uppgifter än',
    "There's inputs which requires input before you can save the document":
      'Det finns fält som måste fyllas i innan dokumentet kan sparas',
    "There's inputs which requires data before you can sign the document":
      'Det finns fält som måste fyllas i innan dokumentet kan signeras',
    'The case will be saved as private': 'Det här ärendet sparas som privat',
    'This cron job sends automatic email reminders for scheduled upcoming activities':
      'Detta Cron job skickar automatiska e-postpåminnelser för kommande planerade aktiviteter',
    'This document has been signed': 'Dokumentet har signerats',
    'This document is signed and locked for editing':
      'Det här dokumentet är signerat och låst för redigering',
    'This document will be locked for editing when saved':
      'Det här dokumentet kommer att låsas för redigering när det sparas',
    'This task is delayed': 'Den här uppgiften är försenad',
    'This field is required': 'Det här fältet måste fyllas i',
    'This is a headline': 'Det här är en rubrik',
    'This is a body text.': 'Det här är en brödtext.',
    'This input only allows numbers':
      'Den här textrutan tillåter enbart nummer',
    'This input only allows regular letters':
      'Den här textrutan tillåter enbart bokstäver',
    Thursday: 'Torsdag',
    Thu: 'Tor',
    Title: 'Rubrik',
    Tuesday: 'Tisdag',
    Tue: 'Tis',
    Type: 'Typ',
    'Type of link': 'Typ av länk',
    Today: 'Idag',
    'Total cost': 'Total kostnad',
    Top: 'Toppen',
    'Top(2)': 'Ovansida',
    'Type your own URL': 'Ange egen URL',
    //U
    URL: 'URL',
    'Use digital sign off': 'Använd digital signering',
    'Use passkey': 'Använd passkey',
    Username: 'Användarnamn',
    'Username or email': 'Användarnamn eller e-post',
    Useraccount: 'Användarkonto',
    User: 'Användare',
    Users: 'Användare',
    'User was added': 'Användaren är skapad',
    'User was deleted': 'Användaren är borttagen',
    'User was updated': 'Användaren uppdaterades',
    'Username is required': 'Du måste ange ett användarnamn',
    'User not found': 'Användaren finns inte',
    Updated: 'Uppdaterad',
    'Update password': 'Uppdatera lösenordet',
    'Updated recently': 'Nyligen updaterad',
    'Upload document from this unit': 'Ladda upp dokument från den här enheten',
    'Upload file from this unit': 'Ladda upp en fil från den här enheten',
    'Uploaded documents': 'Uppladdade dokument',
    Unchecked: 'Avmarkerad',
    Uncomplete: 'Ej genomförd',
    Unpublished: 'Opublicerad',
    'Unpublished (tab)': 'Opublicerade',
    //V
    Version: 'Version',
    Ver: 'Ver',
    View: 'Visa',
    Views: 'Visningar',
    'View improvement': 'Visa förbättring',
    'View report': 'Visa rapport',
    'View safety audit': 'Visa skyddsrond',
    'Video file': 'Video fil',

    //Y
    Yes: 'Ja',
    Yesterday: 'Igår',
    'You are about to begin the safety audit. By clicking on the "Start" -button, you\'ll be asked a number of questions which you and your team will answer during the audit':
      'Du kommer nu att starta den här skyddsronden. Genom att klicka på knappen "Börja", kommer det ställas ett antal frågor som du och ditt team kan svara på under skyddsronden',
    'You are logged out': 'Du är utloggad',
    'You can request a new activation link to your e-mail address if registred':
      'Du kan begära en få en ny aktiveringslänk skickad till din e-postadress om den finns registrerad',
    "You don't have access to this app":
      'Du har inte tillgång till den här appen',
    "You have been invited to OfficeOnServer. To login you'll need to activate your account":
      'Du har blivit inbjuden till OfficeOnServer. För att logga in behöver du aktivera ditt användarkonto först',
    'You have been added as a team member in x safety audits':
      'Du har lagts till som teamdeltagare i x skyddsronder',
    'You have been added as a project team member in x projects':
      'Du har lagts till som projektmedlem i x projekt',
    'You have been suggested as responsible for a':
      'Du har föreslagits som ansvarig för en',
    'You have been suggested as responsible for a(2)':
      'Du har föreslagits som ansvarig för ett',
    "You have requested a new activaton mail. If you haven't, please ignore this email":
      'Du har begärt ett nytt mail för att aktivera ditt användarkonto. Om detta inte stämmer kan du bortse från det här mailet',
    'You have x new cases shared with you':
      'Du har x ärenden som är delade med dig',
    'You have x cases where you are suggested as responsible':
      'Du har x ärenden där du är föreslagen ansvarig',
    'You have x new cases where you are suggested as responsible':
      'Du har x nya ärenden där du är föreslagen ansvarig',
    'You have x declined requests of document approval & publication':
      'Du har x avböjda förfågningar om godkännande och publicering av dokument',
    'You have x new declined requests of document approval & publication':
      'Du har x nya avböjda förfågningar om godkännande och publicering av dokument',
    'You have x requests for approval and publication of documents':
      'Du har x förfrågningar om godkännande och publicering av dokument',
    'You have x new requests for approval and publication of documents':
      'Du har x nya förfrågningar om godkännande och publicering av dokument',
    'You have x requests for approval and publication of flowcharts':
      'Du har x förfrågningar om godkännande och publicering av flödesscheman',
    'You have x new requests for approval and publication of flowcharts':
      'Du har x nya förfrågningar om godkännande och publicering av flödesscheman',
    'You have x declined requests of flowchart approval & publication':
      'Du har x avböjda förfågningar om godkännande och publicering av flödesscheman',
    'You have x new declined requests of flowchart approval & publication':
      'Du har x nya avböjda förfågningar om godkännande och publicering av flödesscheman',
    'You have x requests for approval and publication of flowcharts':
      'Du har x förfrågningar om godkännande och publicering av flödesscheman',
    'You have x new requests for approval and publication of flowcharts':
      'Du har x nya förfrågningar om godkännande och publicering av flödesscheman',
    'You have x suggestions as responsible for safety issue reports':
      'Det finns x tillbud- & olycksrapporter där du är utsedd ansvarig',
    'You have x new suggestions as responsible for safety issue reports':
      'Det finns x nya tillbud- & olycksrapporter där du är utsedd ansvarig',
    'You have x suggestions as responsible for safety audits':
      'Det finns x skyddsronder där du är utsedd ansvarig',
    'You have x new suggestions as responsible for safety audits':
      'Det finns x nya skyddsronder där du är utsedd ansvarig',
    'You have x suggestions as responsible improvements':
      'Det finns x förbättringar där du är utsedd ansvarig',
    'You have x new suggestions as responsible improvements':
      'Det finns x nya förbättringar där du är utsedd ansvarig',
    'You have x suggestions for projects as project manager':
      'Det finns x projekt där du är utsedd projektledare',
    'You have x new suggestions for projects as project manager':
      'Det finns x nya projekt där du är utsedd projektledare',
    'You have x suggestions as responsible for projects tasks':
      'Det finns x projektuppgifter där du är utsedd ansvarig',
    'You have x new suggestions as responsible for projects tasks':
      'Det finns x nya projektuppgifter där du är utsedd ansvarig',
    "You haven't uploaded any file": 'Du måste ladda upp en fil',
    'Your user account are now activated. Go to login page and login with your username or email':
      'Ditt användarkonto är nu aktiverat. Gå till sidan för inloggning och logga in med ditt användarnamn eller e-postadress',
    "You're logged out": 'Du är utloggad',
    "You're logged in": 'Du är inloggad',
    'You have typed an incorrect email address':
      'Du har anget en felaktig emailadress',
    'Safety issue reports where you are responsible':
      'Tillbud- & olycksrapporter som du ansvarar för',
    //W
    'w.': 'v.',
    Weeks: 'Veckor',
    Website: 'Webbsida',
    Wednesday: 'Onsdag',
    Wed: 'Ons',
    'weeks ago': 'veckor sedan',
    'Whats new': 'Vad är nytt',
    Width: 'Bredd',
    'Word file': 'Word fil',
    'Work at height': 'Arbete på höjd',
    'Work environment & climate': 'Arbetsmiljö & klimat',
    Wrote: 'Skrev',
    //Z
    'Zip code': 'Postnummer',
  },
]

export default langObj_sv
