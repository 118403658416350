const langObj_en = [
  {
    /* ENGLISH */

    //0-9
    '404 -Webpage not found': '404 -Webpage not found',

    //A
    'A week ago': 'A week ago',
    Abort: 'Abort',
    'Abort publish request': 'Abort publish request',
    'Abort publish request, are you sure':
      'Abort publish request, are you sure',
    'Abort request': 'Abort request',
    Active: 'Active',
    'Active app': 'Active app',
    Accident: 'Accident',
    Account: 'Account',
    Activities: 'Activities',
    Activited: 'Activited',
    'Activate account': 'Activate account',
    'Activate user account': 'Activate user account',
    'Account settings': 'Account settings',
    'Activation mail send': 'Activation mail send',
    'Activation mail was send': 'Activation mail was send',
    Actual: 'Actual',
    Administrator: 'Administrator',
    Add: 'Add',
    'Add calculating data by clicking on the component in the form':
      'Add calculating data by clicking on the component in the form',
    'Add eForm': 'Add eForm',
    'Add existing documents': 'Add existing documents',
    'Add existing eForm': 'Add existing eForm',
    'Added recently': 'Added recently',
    'Add activity': 'Add activity',
    'Add company logo': 'Add company logo',
    'Add column': 'Add column',
    'Add eForm template': 'Add eForm template',
    'Add new document': 'Add new document',
    'Add new flowchart': 'Add new flowchart',
    'Add new improvement idea': 'Add new improvement idea',
    'Add new project': 'Add new project',
    'Add new report': 'Add new report',
    'Add new row': 'Add new row',
    'Add new task': 'Add new task',
    'Add new user': 'Add new user',
    'Add new category': 'Add new category',
    'Add new sub category': 'Add new sub category',
    'Add new department': 'Add new department',
    'Add new sub department': 'Add new sub department',
    'Add new option': 'Add new option',
    'Add node': 'Add node',
    'Add more decimals': 'Add more decimals',
    'Add user image': 'Add user image',
    'Add new page': 'Add new page',
    'Add task': 'Add task',
    'Add sub task': 'Add sub task',
    'Add who should be responsible for the case':
      'Add who should be responsible for the case',
    'Add who should be responsible for the improvement':
      'Add who should be responsible for the improvement',
    'Add who should be responsible for the safety issue':
      'Add who should be responsible for the safety issue',
    'Add who should be responsible for the safety audit':
      'Add who should be responsible for the safety audit',
    'Add who should be project manager for the project':
      'Add who should be project manager for the project',
    'Add who should be responsible for the task':
      'Add who should be responsible for the task',
    'Add swimlane': 'Add swimlane',
    All: 'All',
    'All(2)': 'All',
    'All documents': 'All documents',
    'All flowcharts': 'All flowcharts',
    'All projects': 'All projects',
    'All registered cases': 'All registered cases',
    'A repeated password is required': 'A repeated password is required',
    'An activation mail will be send to': 'An activation mail will be send to',
    Archive: 'Archive',
    'Arrows & lines': 'Arrows & lines',
    'An email message has been send to ': 'An email message has been send to ',
    ' with further instructions': ' with further instructions',
    August: 'August',
    Aug: 'Aug',
    'Audit area': 'Audit area',
    'Audit documentation': 'Audit documentation',
    April: 'April',
    Apr: 'Apr',
    Apps: 'Apps',
    'Approved by': 'Approved by',
    'Approve & publish': 'Approve & publish',
    'Approval & publishing request': 'Approval & publishing request',
    'Align horizontally': 'Align horizontally',
    'Align vertically': 'Align vertically',
    Auto: 'Auto',
    Awaits: 'Awaits',

    //B
    Back: 'Back',
    'Be sure to save the questions you answered before completing the safety audit. You do not have to answer all the questions at once, but can resume the security round at a later time':
      'Be sure to save the questions you answered before completing the security round. You do not have to answer all the questions at once, but can resume the safety audit at a later time',
    'Big effort': 'Big effort',
    'Big effect': 'Big effect',
    'Big risk': 'Big risk',
    'Big consequence': 'Big consequence',
    Budget: 'Budget',
    By: 'By',
    'Body text': 'Body text',
    Bottom: 'Bottom',
    'Bottom(2)': 'Bottomside',

    //C
    Case: 'Case',
    'Case is now private': 'Case is now private',
    'Case number': 'Case number',
    'Case was deleted': 'Case was deleted',
    Cases: 'Cases',
    'Case was added': 'Case was added',
    'Cases where you are responsible': 'Cases where you are responsible',
    'Cases shared with you': 'Cases shared with you',
    Category: 'Category',
    Categories: 'Categories',
    "Can't get inserted id": "Can't get inserted id",
    'Case was moved': 'Case was moved',
    'Case was saved': 'Case was saved',
    'Categories was saved': 'Categories was saved',
    'Capacity for projects': 'Capacity for projects',
    Checked: 'Checked',
    Chemicals: 'Chemicals',
    Change: 'Change',
    'Changed by': 'Changed by',
    'Change password': 'Change password',
    'Change company image': 'Change company image',
    'Change user image': 'Change user image',
    'Change to complete': 'Change to complete',
    'Change to complete, are you sure': 'Change to complete, are you sure',
    'Change to uncomplete': 'Change to uncomplete',
    'Change to uncomplete, are you sure': 'Change to uncomplete, are you sure',
    'Change log': 'Change log',
    Checkbox: 'Checkbox',
    City: 'City',
    'Clear all notifiactions': 'Clear all notifiactions',
    'Click on icon to upload file': 'Click on icon to upload file',
    'Click on image to upload another file':
      'Click on image to upload another file',
    'Click on icon to upload image': 'Click on icon to upload image',
    'Click on the button to login': 'Click on the button to login',
    'Click on the button below to activate your account. This session is valid for 3 minutes':
      'Click on the button below to activate your account. This session is valid for 3 minutes',
    'Consequence factor': 'Consequence factor',
    'Cost(2)': 'Cost',
    'Cost follow-up': 'Cost follow-up',
    'Cost allocation': 'Cost allocation',
    Column: 'Column',
    'Continued on the next page': 'Continued on the next page',
    'Continue by clicking the button with the status you want to move the case with':
      'Continue by clicking the button with the status you want to move the case with',
    'Continue by clicking the button with the status you want to move the improvement with':
      'Continue by clicking the button with the status you want to move the improvement with',
    "Continue by clicking the button 'Completed' to move the safety issue report":
      "Continue by clicking the button 'Completed' to move the safety issue report",
    Close: 'Close',
    Comments: 'Comments',
    Company: 'Company',
    'Company info was updated': 'Company info was updated',
    'Company name': 'Company name',
    'Compressed file': 'Compressed file',
    Completed: 'Completed',
    'Completed(2)': 'Completed',
    Component: 'Component',
    Continue: 'Continue',
    'Continue(2)': 'Continue',
    Condition: 'Condition',
    'Create a link to another flowchart, document or a custom link below':
      'Create a link to another flowchart, document or a custom link below',
    Created: 'Created',

    'Created by': 'Created by',
    'Create calculation': 'Create calculation',
    'Create new': 'Create new',
    'Create new audit': 'Create new audit',
    'Create new e-document': 'Create new e-document',
    'Create new safety audit': 'Create new safety audit',
    'Create new project': 'Create new project',
    'Create one': 'Create one',
    'Create link': 'Create link',

    //D
    Date: 'Date',
    'Data type': 'Data type',
    Dashed: 'Dashed',
    'Date of the occurrence': 'Date of the occurrence',
    'days ago': 'days ago',
    Darkmode: 'Darkmode',
    Dashboard: 'Dashboard',
    Days: 'Days',
    December: 'December',
    Dec: 'Dec',
    Decline: 'Decline',
    Declined: 'Declined',
    'Declined by': 'Declined by',
    'Decline request': 'Decline request',
    'Declined requests': 'Declined requests',
    'Decline request, are you sure': 'Decline request, are you sure',
    'Default value': 'Default value',
    Description: 'Description',
    'Describe the course of events': 'Describe the course of events',
    'Description of the event': 'Description of the event',
    Department: 'Department',
    Delete: 'Delete',
    'Delete case': 'Delete case',
    'Delete case, are you sure': 'Delete case, are you sure',
    'Delete document': 'Delete document',
    'Delete document, are you sure': 'Delete document, are you sure',
    'Delete file': 'Delete file',
    'Delete file, are you sure': 'Delete file, are you sure',
    'Delete flowchart': 'Delete flowchart',
    'Delete flowchart, are you sure': 'Delete flowchart, are you sure',
    'Delete report': 'Delete report',
    'Delete report, are you sure': 'Delete report, are you sure',
    'Delete improvement': 'Delete improvement',
    'Delete improvement, are you sure': 'Delete improvement, are you sure',
    'Delete task': 'Delete task',
    'Delete task, are you sure': 'Delete task, are you sure',
    'Delete project phase': 'Delete project phase',
    'Delete project phase, are you sure': 'Delete project phase, are you sure',
    'Delete project': 'Delete project',
    'Delete project, are you sure': 'Delete project, are you sure',
    'Delete user': 'Delete user',
    'Delete user, are you sure': 'Delete user, are you sure',
    'Delete department': 'Delete department',
    'Delete department, are you sure': 'Delete department, are you sure',
    'Delete category': 'Delete category',
    'Delete category, are you sure': 'Delete category, are you sure',
    'Delete selected': 'Delete selected',
    'Delete selected, are you sure': 'Delete selected, are you sure',
    'Delete selected documents': 'Delete selected documents',
    'Delete selected documents, are you sure':
      'Delete selected documents, are you sure',
    'Delete selected cases': 'Delete selected cases',
    'Delete selected cases, are you sure':
      'Delete selected cases, are you sure',
    'Delete selected flowcharts': 'Delete selected flowcharts',
    'Delete selected flowcharts, are you sure':
      'Delete selected flowcharts, are you sure',
    'Delete selected reports': 'Delete selected reports',
    'Delete selected reports, are you sure':
      'Delete selected reports, are you sure',
    'Delete safety audit': 'Delete safety audit',
    'Delete selected safety audits': 'Delete selected safety audits',
    'Delete safety audit, are you sure': 'Delete safety audit, are you sure',
    'Delete selected safety audits, are you sure':
      'Delete selected safety audits, are you sure',
    'Delete selected improvements': 'Delete selected improvements',
    'Delete selected improvements, are you sure':
      'Delete selected improvements, are you sure',
    'Delete selected projects': 'Delete selected projects',
    'Delete selected projects, are you sure':
      'Delete selected improvements, are you sure',
    'Delete selected users': 'Delete selected users',
    'Delete selected users, are you sure':
      'Delete selected users, are you sure',
    'Delete page': 'Delete page',
    'This type of case is private and will only be visible to you and those you share the case with':
      'This type of case is private and will only be visible to you and those you share the case with',
    Deviation: 'Deviation',
    Deviations: 'Deviations',
    'Deviations require a comment': 'Deviations require a comment',
    'Digitally signed': 'Digitally signed',
    Document: 'Document',
    Documents: 'Documents',
    'Document already exists': 'Filename already exists',
    'Document categories': 'Document categories',
    'Document name': 'Document name',
    'Document was added': 'Document was added',
    'Document was deleted': 'Document was deleted',
    'Document was removed': 'Document was removed',
    'Document was published': 'Document was published',
    'Document was updated': 'Document was updated',
    'Document was saved': 'Document was saved',
    'Document was signed': 'Document was signed',
    'Download as JSON': 'Download as JSON',
    'Document type': 'Document type',
    'Document no. is required': 'Document no. is required',
    'Document no.': 'Document no.',
    'Document number': 'Document number',
    'Document publish requests': 'Document publish requests',
    'Doc.no.': 'Doc.no.',
    "Don't save": "Don't save",
    'Drugs & abuse': 'Drugs & abuse',
    //E
    'E-document': 'E-document',
    Effort: 'Effort',
    Effect: 'Effect',
    Edit: 'Edit',
    Editor: 'Editor',
    'Edit case': 'Edit case',
    'Edit document': 'Edit document',
    'Edit flowchart': 'Edit flowchart',
    'Edit improvement': 'Edit improvement',
    'Edit report': 'Edit report',
    'Edit safety audit': 'Edit safety audit',
    'Edit task': 'Edit task',
    'Edit user': 'Edit user',
    'Edit company color': 'Edit company color',
    'Edit profile color': 'Edit profile color',
    'Edit project': 'Edit project',
    'Edit project phase': 'Edit project phase',
    'Edit report': 'Edit report',
    'Editmode is not enabled': 'Editmode is not enabled',
    Ergonomics: 'Ergonomics',
    'Electrical safety': 'Electrical safety',
    Email: 'Email',
    'Email address': 'Email address',
    'Email: automatic reminders': 'Email: automatic reminders',
    'Email is required': 'Email is required',
    'Email: Send notifications': 'Email: Send notifications',
    End: 'End',
    'End date': 'End date',
    English: 'English',
    'Export JSON': 'Export JSON',
    'Excel file': 'Excel file',
    'Existing documents': 'Existing documents',
    'External cost': 'External cost',

    //F
    'Failed to save passkey': 'Failed to save passkey',
    February: 'February',
    Feb: 'Feb',
    Files: 'Files',
    'Files & documents': 'Files & documents',
    'File size is larger than allowed': 'File size is larger than allowed',
    'File type': 'File type',
    'File name': 'File name',
    'File was added': 'File was added',
    'File was uploaded': 'File was uploaded',
    'Filename already exists': 'Filename already exists',
    Filter: 'Filter',
    'Fire protection': 'Fire Protection',
    'First name': 'First name',
    'First name is required': 'First name is required',
    'First aid and medical care': 'First aid and medical care',
    Flowchart: 'Flowchart',
    'Flowchart name': 'Flowchart name',
    Flowcharts: 'Flowcharts',
    'Flowchart publish requests': 'Flowchart publish requests',
    'Flowchart was added': 'Flowchart was added',
    'Flowchart was saved': 'Flowchart was saved',
    'Flowchart was deleted': 'Flowchart was deleted',
    'Flowchart was published': 'Flowchart was published',
    'Flcrt.No.': 'Flcrt.No.',
    'Focus area': 'Focus area',
    'Forgot password': 'Forgot password',
    Friday: 'Friday',
    Fri: 'Fri',
    Form: 'Form',

    //G
    'Gantt chart': 'Gantt chart',
    GBP: 'GBP',
    'Go to login page': 'Go to login page',
    'Go to regular login page': 'Go to regular login page',
    //H
    'h/week': 'h/week',
    Heading: 'Heading',
    Headline: 'Headline',
    Hi: 'Hi',
    Home: 'Home',
    'Hours of workload': 'Hours of workload',
    'Hour rate': 'Hour rate',

    //I
    'I forgot my password': 'I forgot my password',
    'If you make the case private, it will only be visible to you and those you share the case with':
      'If you make the case private, it will only be visible to you and those you share the case with',
    "It seems you don't have permission to enter this page":
      "It seems you don't have permission to enter this page",
    Idle: 'Idle',
    Inactive: 'Inactive',
    Incident: 'Incident',
    'Internal cost': 'Internal cost',
    Integer: 'Integer',
    'Invalid temporary key': 'Invalid temporary key',
    'Invalid password': 'Invalid password',
    'Invalid session': 'Invalid session',
    'Invalid filename': 'Invalid filename',
    'Invalid file type': 'Invalid file type',
    'Invalid Image type': 'Invalid Image type',
    'Invite more participant': 'Invite more participant',
    Input: 'Input',
    'Image file': 'Image file',
    'Image was updated': 'Image was updated',
    'Image was removed': 'Image was removed',
    'Implemented countermeasure': 'Implemented countermeasure',
    Improvement: 'Improvement',
    Improvements: 'Improvements',
    'Improvement area': 'Improvement area',
    'Improvement title': 'Improvement title',
    'Improvement was added': 'Improvement was added',
    'Improvement was deleted': 'Improvement was deleted',
    'Improvement was saved': 'Improvement was saved',
    'Improvement was moved': 'Improvement was moved',
    'Improvements where you are responsible':
      'Improvements where you are responsible',
    'Improvement headline': 'Improvement headline',
    'Improvement number': 'Improvement number',
    'ISO 45001': 'ISO 45001',
    //J
    January: 'January',
    Jan: 'Jan',
    July: 'July',
    Jul: 'Jul',
    June: 'June',
    Jun: 'Jun',
    //L
    Label: 'Label',
    Language: 'Language',
    'Last modified documents': 'Last modified documents',
    Left: 'Left',
    'Left(2)': 'Leftside',
    'Left menu: New/updated items': 'Left menu: New/updated items',
    Layout: 'Layout',
    'Last name': 'Last name',
    'Last name is required': 'Last name is required',
    'Last login': 'Last login',
    'Last modified': 'Last modified',
    'Last modified by': 'Last modified by',
    'Learn more': 'Learn more',
    'Link to': 'Link to',
    'Loading...': 'Loading...',
    'Lock the document after sign off': 'Lock the document after sign off',
    Login: 'Login',
    'Login again': 'Login again',
    'Login without password': 'Login without password',
    'Log out': 'Log out',
    'Logged in today': 'Logged in today',
    'Logged yesterday': 'Logged yesterday',
    'Logged in': 'Logged in',
    'Logged in a week ago': 'Logged in a week ago',
    'Logged in over a year ago': 'Logged in over a year ago',
    'Long term counter measure': 'Long term counter measure',

    //M
    'Machine safety': 'Machine safety',
    'Make active': 'Make active',
    'Make idle': 'Make idle',
    'Make this project active, are you sure':
      'Make this project active, are you sure',
    'Make this project idle, are you sure':
      'Make this project idle, are you sure',
    'Make to form': 'Make to form',
    'Make private': 'Make private',
    'Make public': 'Make public',
    May: 'May',
    March: 'March',
    Mar: 'Mar',
    Margins: 'Margins',
    Minimize: 'Minimize',
    Maximize: 'Maximize',
    Media: 'Media',
    Message: 'Message',
    Month: 'Month',
    'Month(2)': 'Month',
    'month ago': 'month ago',
    Modified: 'Modified',
    'Modified by': 'Modified by',
    Modules: 'Modules',
    'Must be at least 2 characters': 'Must be at least 2 characters',
    'Must be at least 6 characters': 'Must be at least 6 characters',
    'Must be a number': 'Must be a number',
    'Must not exceed 20 characters': 'Must not exceed 20 characters',
    'Must not exceed 100 characters': 'Must not exceed 100 characters',
    'More info': 'More info',
    Monday: 'Monday',
    Mon: 'Mon',
    Move: 'Move',
    'Move as completed': 'Move as completed',
    'Move as idle': 'Move as idle',
    'Move as declined': 'Move as declined',
    'Move to planned': 'Move to planned',
    "Move this savety audit to status 'planned', are you sure":
      "Move this savety audit to status 'planned', are you sure",
    'Move to ongoing': 'Move to ongoing',
    "Move this safety audit to status 'ongoing', are you sure":
      "Move this safety audit to status 'ongoing', are you sure",
    "Move this improvement to status 'ongoing', are you sure":
      "Move this improvement to status 'ongoing', are you sure",
    'Move to archived': 'Move to archived',
    'Move to completed': 'Move to completed',
    "Move this case to status 'archived', are you sure":
      "Move this case to status 'archived', are you sure",
    "Move this improvement to status 'archived', are you sure":
      "Move this improvement to status 'archived', are you sure",
    "Move this safety issue to status 'ongoing', are you sure":
      "Move this safety issue to status 'ongoing', are you sure",
    'Move to archived': 'Move to completed',
    "Move this safety issue to status 'completed', are you sure":
      "Move this safety issue to status 'completed', are you sure",
    "Move this project to status 'planned', are you sure":
      "Move this project to status 'planned', are you sure",
    "Move this project to status 'ongoing', are you sure":
      "Move this project to status 'ongoing', are you sure",
    "Move this project to status 'archived', are you sure":
      "Move this project to status 'archived', are you sure",
    "Move this project to status 'completed', are you sure":
      "Move this project to status 'completed', are you sure",
    'My activities': 'My activities',
    'My cases': 'My cases',
    'My user account': 'My user account',
    //N
    Name: 'Name',
    'Never logged in': 'Never logged in',
    New: 'New',
    Next: 'Next',
    'New bodytext': 'New bodytext',
    'New checkbox': 'New checkbox',
    'New datepicker': 'New datepicker',
    'New input': 'New input',
    'New headline': 'New headline',
    'New password': 'New password',
    'New selectbox': 'New selectbox',
    'New item': 'New item',
    Number: 'Number',
    'No activities': 'No activities',
    'No remark': 'No remarks',
    'No rows': 'No rows',
    'No suggestion': 'No suggestion',
    'No passkey found': 'No passkey found',
    'No.': 'No.',
    'No one': 'No one',
    'No of days to notify objects as new/updated':
      'No of days to notify objects as new/updated',
    'Non-numeric': 'Non-numeric',
    'Number of days that the reminder should be sent before the planned date of the event':
      'Number of days that the reminder should be sent before the planned date of the event',
    Numeric: 'Numeric',
    Notifiaction: 'Notifiaction',
    Notifiactions: 'Notifiactions',
    Nodes: 'Nodes',
    'Not a valid email address': 'Not a valid email address',
    'Not approved': 'Not approved',
    'Not reported': 'Not reported',
    November: 'November',
    Nov: 'Nov',
    Normal: 'Normal',
    'New idea': 'New idea',
    'New ideas': 'New ideas',
    'New reports': 'New reports',
    'New project phase': 'New project phase',

    //O
    Of: 'Of',
    Observation: 'Observation',
    Observations: 'Observations',
    October: 'October',
    Oct: 'Oct',
    'Occupancy in hours': 'Occupancy in hours',
    Occurred: 'Occurred',
    Ok: 'Ok',
    'on the emaildress': 'on the emaildress',
    Ongoing: 'Ongoing',
    'Oops!': 'Oops!',
    "Oops! User dosen't exist": "Oops! User dosen't exist",
    'Oops! something went wrong': 'Oops! something went wrong',
    "Oops! You don't have permission to do this":
      "Oops! You don't have permission to do this",
    Or: 'Or',
    Order: 'Order',
    Organization: 'Organization',
    'Organization was saved': 'Organization was saved',
    Other: 'Other',
    'Other apps': 'Other apps',
    'Other settings': 'Other settings',
    'Over a year ago': 'Over a year ago',
    Overview: 'Overview',
    'Open case': 'Open case',
    'Open e-document': 'Open e-document',
    'Open flowchart': 'Open flowchart',
    'Open project': 'Open project',
    Options: 'Options',

    //P
    'Pallet rack, forklift & gantry lifting':
      'Pallet rack, forklift & gantry lifting',
    Password: 'Password',
    'Password must be at least 6 characters':
      'Password must be at least 6 characters',
    'Passwords must match': 'Passwords must match',
    'Password was updated': 'Password was updated',
    Page: 'Page',
    'Page added': 'Page added',
    'Page was removed': 'Page was removed',
    'PDF file': 'PDF file',
    Permissions: 'Permissions',
    'Personal Protective Equipment': 'Personal Protective Equipment',
    'Place holder': 'Place holder',
    Planning: 'Planning',
    Planned: 'Planned',
    'Planned(2)': 'Planned',
    'Please choose a planned date for the audit':
      'Please choose a planned date for the audit',
    'Please choose a start date': 'Please choose a start date',
    'Please choose a end date': 'Pleas choose a end date',
    'Please close the webbrowser': 'Please close the webbrowser',
    'Please choose what type of document to add':
      'Please choose what type of document to add',
    'Please choose if you want to add a file or document':
      'Please choose if you want to add a file or document',
    'Please enter a strong password for your user account below to activate your user account. To login, use the email address which you recived the activation message as your username':
      'Please enter a strong password for your user account below to activate your user account. To login, use the email address which you recived the activation message as your username',
    'Please wait': 'Please wait',
    'please indicate how this improvement should be prioritized by clicking in the matrix':
      'Please indicate how this improvement should be prioritized by clicking in the matrix',
    'Please indicate how this Safety issue should be prioritized by clicking in the matrix':
      'Please indicate how this Safety issue should be prioritized by clicking in the matrix',
    'Password is required': 'Password is required',
    'Passkey validated and accepted': 'Passkey validated and accepted',
    "Passkey didn't validate": "Passkey didn't validate",
    'Passkey saved successfully': 'Passkey saved successfully',
    'Private mode': 'Private mode',
    'Problem area': 'Problem area',
    Progress: 'Progress',
    Project: 'Project',
    Projects: 'Projects',
    'Project is active': 'Project is active',
    'Project is idle': 'Project is idle',
    'Project is not saved, save first': 'Project is not saved, save first',
    'Project manager': 'Project manager',
    'Project number': 'Project number',
    'Project title': 'Project title',
    'Project tasks where you are responsible':
      'Project tasks where you are responsible',
    'Project was created and added': 'Project was created and added',
    'Project was saved': 'Project was saved',
    'Project was moved': 'Project was moved',
    'Projects where you are project manager':
      'Projects where you are project manager',
    'Projects teams in which you are part of':
      'Projects teams in which you are part of',
    'Project phase was added': 'Project phase was added',
    Print: 'Print',
    'Primary app was updated': 'Primary app was updated',
    Prioritize: 'Prioritize',
    'Prio.': 'Prio.',
    'Prio. points': 'Prio. pints',
    Prioritization: 'Prioritization',
    'Project was added': 'Project was added',
    'Project was deleted': 'Project was deleted',
    Profile: 'Profile',
    Process: 'Process',
    Publish: 'Publish',
    'Publish document': 'Publish document',
    'Publish document, are you sure': 'Publish document, are you sure',
    'Publish selected documents': 'Publish selected documents',
    'Publish selected documents, are you sure':
      'Publish selected documents, are you sure',
    'Publish flowchart': 'Publish flowchart',
    'Publish flowchart, are you sure': 'Publish flowchart, are you sure',
    'Publish selected flowcharts': 'Publish selected flowcharts',
    'Publish selected flowcharts, are you sure':
      'Publish selected flowcharts, are you sure',
    'Published & approved': 'Published & approved',
    'Published recently': 'Published recently',
    'Post(2)': 'Post',
    'Post external cost': 'Post external cost',
    'Powerpoint file': 'Powerpoint file',
    Public: 'Public',

    //Q
    Question: 'Question',
    Questions: 'Questions',
    'Questions answered': 'Questions answered',

    //R
    Registered: 'Registered',
    'Register passkey': 'Register passkey',
    'Registred since': 'Registred since',
    'Registered recently': 'Registered recently',
    'Register new case': 'Register new case',
    'Regular user': 'Regular user',
    Remove: 'Remove',
    'Remove decimals': 'Remove decimals',
    'Remove image': 'Remove image',
    'Remove link': 'Remove link',
    'Remove link, are you sure': 'Remove link, are you sure',
    Report: 'Report',
    'Report number': 'Report number',
    'Report was deleted': 'Report was deleted',
    'Report was added': 'Report was added',
    'Report was saved': 'Report was saved',
    'Report was moved': 'Report was moved',
    'Request send to': 'Request send to',
    'Request send from': 'Request send from',
    'Request was declined': 'Request was declined',
    'Send activation mail': 'Send activation mail',
    'Reorder by start date': 'Reorder by start date',
    'Repeat password': 'Repeat password',
    Restore: 'Restore',
    Result: 'Result',
    Responsible: 'Responsible',
    'Responsible for the safety audit': 'Responsible for the safety audit',
    'Responsible missing': 'Responsible missing',
    'Restore password': 'Restore password',
    'Resource planning': 'Resource planning',
    Requests: 'Requests',
    Required: 'Required',
    'Request was aborted': 'Request was aborted',
    'Request was send to user': 'Request was send to user',
    'Risk assessment': 'Risk assessment',
    'Risk factor': 'Risk factor',
    Right: 'Right',
    'Right(2)': 'Rightside',
    'Root cause': 'Root cause',
    Row: 'Row',

    //S
    Safety: 'Safety & health',
    'Safety audits': 'Safety audits',
    'Safety audit': 'Safety audit',
    'Safety audit started': 'Safety audit started',
    'Safety audit was deleted': 'Safety audit was deleted',
    'Safety audit was created and added': 'Safety audit was created and added',
    'Safety audit was saved': 'Safety audit was saved',
    'Safety audit was moved': 'Safety audit was moved',
    'Safety audits where you are responsible':
      'Safety audits where you are responsible',
    'Safety audit started and moved to ongong safety audits':
      'Safety audit started and moved to ongong safety audits',
    'Safety issue report': 'Safety issue report',
    'Safety issue reports': 'Safety issue reports',
    'Safety & health': 'Safety & health',
    Saturday: 'Saturday',
    Sat: 'Sat',
    Save: 'Save',
    'Save project': 'Save project',
    'Send notification of assigned responsibility by email to users':
      'Send notification of assigned responsibility by email to users',
    'Send notification to added team member by email':
      'Send notification to added team member by email',
    'Send publish request': 'Send publish request',
    'Save as completed': 'Save as completed',
    'Saved recently': 'Saved recently',
    Settings: 'Settings',
    'Send login link': 'Send login link',
    Search: 'Search',
    'Search among project team members': 'Search among project team members',
    'Search user': 'Search user',
    'Search everywhere': 'Search everywhere',
    September: 'September',
    Sep: 'Sep',
    'Session has expired': 'Session has expired',
    Select: 'Select',
    'Select date': 'Select date',
    'select which users you want to share the case with':
      'select which users you want to share the case with',
    'Selected documents was deleted': 'Selected documents was deleted',
    'Selected cases was deleted': 'Selected doccasesuments was deleted',
    'Selected rows was deleted': 'Selected rows was deleted',
    'Selected files was deleted': 'Selected files was deleted',
    'Selected documents was published': 'Selected documents was published',
    'Selected flowcharts was deleted': 'Selected flowcharts was deleted',
    'Selected flowcharts was published': 'Selected flowcharts was published',
    'Selected reports was deleted': 'Selected reports was deleted',
    'Selected improvements was deleted': 'Selected improvements was deleted',
    'Selected projects was deleted': 'Selected projects was deleted',
    'Selected users was deleted': 'Selected users was deleted',
    'Set primary app on login': 'Set primary app on login',
    Swedish: 'Swedish',
    Send: 'Send',
    'Send to': 'Send to',
    'Send from': 'Send from',
    'Send a request to ask for approval and publishment of this document from someone else in your organization':
      'Send a request to ask for approval and publishment of this document from someone else in your organization',
    'Send a request to ask for approval and publishment of this flowchart from someone else in your organization':
      'Send a request to ask for approval and publishment of this flowchart from someone else in your organization',
    'Short term counter measure': 'Short term counter measure',
    Size: 'Size',
    'Suggested responsible': 'Suggested responsible',
    'Suggestions for improvement': 'Suggestions for improvement',
    'Super user': 'Super user',
    'Sound file': 'Sound file',
    Share: 'Share',
    'Share case': 'Share case',
    'Shared by': 'Shared by',
    'Shared with': 'Shared with',
    'Show archived cases': 'Show archived cases',
    'Show archived cases only': 'Show archived cases only',
    'Show completed tasks': 'Show completed tasks',
    'Show result': 'Show result',
    'Show inactive users': 'Show inactive users',
    'Show inactive users only': 'Show inactive users only',
    'Show my activities': 'Show my activities',
    'Show processed documents': 'Show processed documents',
    'Show processed documents only': 'Show processed documents only',
    'Show processed flowcharts': 'Show processed flowcharts',
    'Show processed flowcharts only': 'Show processed flowcharts only',
    Signed: 'Signed',
    Signature: 'Signature',
    'Sign off': 'Sign off',
    'Small effort': 'Small effort',
    'Small effect': 'Small effect',
    'Small risk': 'Small risk',
    'Small consequence': 'Small consequence',
    Solid: 'Solid',
    Start: 'Start',
    'Start(2)': 'Start',
    'Start(3)': 'Start',
    'Start date': 'Start date',
    "Start date can't occour after end date":
      "Start date can't occour after end date",
    Status: 'Status',
    'Stress & psychosocial work environment':
      'Stress & psychosocial work environment',
    String: 'String',
    Storage: 'Storage',
    Sort: 'Sort',
    'Street address': 'Street address',
    Subheading: 'Subheading',
    Sunday: 'Sunday',
    Sun: 'Sun',
    'System settings': 'System settings',
    'System settings was saved': 'System settings was saved',
    Swimlane: 'Swimlane',

    //T
    Task: 'Task',
    Tasks: 'Tasks',
    'Task was added': 'Task was added',
    'Task was deleted': 'Task was deleted',
    'Task was updated': 'Task was updated',
    Team: 'Team',
    Text: 'Text',
    'Safety audit teams you are part of': 'Safety audit teams you are part of',
    'Team was saved': 'Team was saved',
    Telephone: 'Telephone',
    'Text document': 'Text document',
    'Text file': 'Text file',
    'The audit has not been started yet': 'The audit has not been started yet',
    'The file was deleted': 'The file was deleted',
    'The password has been restored successfully':
      'The password has been restored successfully',
    'The file is empty': 'The file is empty',
    'The operation ether timeout or aborted':
      'The operation ether timeout or aborted',
    'There are a total of x activities that concern you':
      'There are a total of x activities that concern you',
    'There are no activities that concern you': 'There is no activities',
    'There is no responsible picked. Choose a responsible first':
      'There is no responsible picked. Pick a responsible first',
    'There is no project manager picked. Choose one first':
      'There is no project manager picked. Choose one first',
    'There is no tasks yet': 'There is no tasks yet',
    "There's inputs which requires input before you can save the document":
      "There's inputs which requires input before you can save the document",
    "There's inputs which requires data before you can sign the document":
      "There's inputs which requires data before you can sign the document",
    'The case will be saved as private': 'The case will be saved as private',
    'This cron job sends automatic email reminders for scheduled upcoming activities':
      'This cron job sends automatic email reminders for scheduled upcoming activities',
    'This document has been signed': 'This document has been signed',
    'This document is signed and locked for editing':
      'This document is signed and locked for editing',
    'This document will be locked for editing when saved':
      'This document will be locked for editing when saved',
    'This task is delayed': 'This task is delayed',
    'This field is required': 'This field is required',
    'This is a headline': 'This is a headline',
    'This is a body text.': 'This is a body text.',
    'This input only allows numbers': 'This input only allows numbers',
    'This input only allows regular letters':
      'This input only allows regular letters',
    Thursday: 'Thursday',
    Thu: 'Thu',
    Title: 'Title',
    Tuesday: 'Tuesday',
    Tue: 'Tue',
    Type: 'Type',
    'Type of link': 'Type of link',
    Today: 'Today',
    'Total cost': 'Total cost',
    Top: 'Top',
    'Top(2)': 'Topside',
    'Type your own URL': 'Type your own URL',

    //U
    URL: 'URL',
    'Use digital sign off': 'Use digital sign off',
    'Use passkey': 'Use passkey',
    Username: 'Username',
    'Username or email': 'Username or email',
    Useraccount: 'Useraccount',
    User: 'User',
    Users: 'Users',
    'User was added': 'User was added',
    'User was deleted': 'User was deleted',
    'User was updated': 'User was updated',
    'Username is required': 'Username is required',
    'User not found': 'User not found',
    Updated: 'Updated',
    'Update password': 'Update password',
    'Updated recently': 'Updated recently',
    'Upload document from this unit': 'Upload document from unit',
    'Upload file from this unit': 'Upload file from this unit',
    'Uploaded documents': 'Uploaded documents',
    Unchecked: 'Unchecked',
    Uncomplete: 'Uncomplete',
    Unpublished: 'Unpublished',
    'Unpublished (tab)': 'Unpublished',
    //V
    Version: 'Version',
    Ver: 'Ver',
    View: 'View',
    Views: 'Views',
    'View improvement': 'View improvement',
    'View report': 'View report',
    'View safety audit': 'View safety audit',
    'Video file': 'Video file',

    //Y
    Yes: 'Yes',
    Yesterday: 'Yesterday',
    'You are about to begin the safety audit. By clicking on the "Start" -button, you\'ll be asked a number of questions which you and your team will answer during the audit':
      'You are about to begin the safety audit. By clicking on the "Start" -button, you\'ll be asked a number of questions which you and your team will answer during the audit',
    'You are logged out': 'You have being logged out',
    'You can request a new activation link to your e-mail address if registred':
      'You can request a new activation link to your e-mail address if registred',
    "You don't have access to this app": "You don't have access to this app",
    "You have been invited to OfficeOnServer. To login you'll need to activate your account":
      "You have been invited to OfficeOnServer. To login you'll need to activate your account",
    'You have been added as a team member in x safety audits':
      'You have been added as a team member in x safety audits',
    'You have been added as a project team member in x projects':
      'You have been added as a project team member in x projects',
    'You have been suggested as responsible for a':
      'You have been suggested as responsible for a',
    'You have been suggested as responsible for a(2)':
      'You have been suggested as responsible for a',
    "You have requested a new activaton mail. If you haven't, please ignore this email":
      "You have requested a new activaton mail. If you haven't, please ignore this email",
    'You have x new cases shared with you':
      'You have x new cases shared with you',
    'You have x cases where you are suggested as responsible':
      'You have x cases where you are suggested as responsible',
    'You have x new cases where you are suggested as responsible':
      'You have x new cases where you are suggested as responsible',
    'You have x declined requests of document approval & publication':
      'You have x declined requests of document approval & publication',
    'You have x new declined requests of document approval & publication':
      'You have x ewn declined requests of document approval & publication',
    'You have x requests for approval and publication of documents':
      'You have x requests for approval and publication of documents',
    'You have x new requests for approval and publication of documents':
      'You have x new requests for approval and publication of documents',
    'You have x declined requests of flowchart approval & publication':
      'You have x declined requests of flowchart approval & publication',
    'You have x new declined requests of flowchart approval & publication':
      'You have x new declined requests of flowchart approval & publication',
    'You have x requests for approval and publication of flowcharts':
      'You have x requests for approval and publication of flowcharts',
    'You have x new requests for approval and publication of flowcharts':
      'You have x new requests for approval and publication of flowcharts',
    'You have x suggestions as responsible for safety issue reports':
      'You have x suggestions as responsible for safety issue reports',
    'You have x new suggestions as responsible for safety issue reports':
      'You have x new suggestions as responsible for safety issue reports',
    'You have x suggestions as responsible for safety audits':
      'You have x suggestions as responsible for safety audits',
    'You have x new suggestions as responsible for safety audits':
      'You have x new suggestions as responsible for safety audits',
    'You have x suggestions as responsible improvements':
      'You have x suggestions as responsible improvements',
    'You have x new suggestions as responsible improvements':
      'You have x new suggestions as responsible improvements',
    'You have x suggestions for projects as project manager':
      'You have x suggestions for projects as project manager',
    'You have x new suggestions for projects as project manager':
      'You have x new suggestions for projects as project manager',
    'You have x suggestions as responsible for projects tasks':
      'You have x suggestions as responsible for projects tasks',
    'You have x new suggestions as responsible for projects tasks':
      'You have x new suggestions as responsible for projects tasks',
    "You haven't uploaded any file": "You haven't uploaded any file",
    'Your user account are now activated. Go to login page and login with your username or email':
      'Your user account are now activated. Go to login page and login with your username or email',
    "You're logged out": "You're logged out",
    "You're logged in": "You're logged in",
    'You have typed an incorrect email address':
      'You have typed an incorrect email address',
    'Safety issue reports where you are responsible':
      'Safety issue reports where you are responsible',

    //W
    'w.': 'w.',
    Weeks: 'Weeks',
    Website: 'Website',
    Wednesday: 'Wednesday',
    Wed: 'Wed',
    'weeks ago': 'weeks ago',
    'Whats new': 'Whats new',
    Width: 'Width',
    'Word file': 'Word file',
    'Work at height': 'Work at height',
    'Work environment & climate': 'Work environment & climate',
    Wrote: 'Wrote',
    //Z
    'Zip code': 'Zip code',
  },
]

export default langObj_en
