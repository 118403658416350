import { createTheme } from "@mui/material/styles";

/**
 * LIGHT THEME
 */
export const officeonserver_themeLight = createTheme({
  palette: {
    mode: "light", // 'light' or 'dark'
    background: {
      default: "#ccd2db",
    },
    primary: {
      main: "#00aaff",
      contrastText: "#fff", //button text white instead of black
    },
    secondary: {
      main: "#6747c4",
    },
    error: {
      main: "#f36f70",
      contrastText: "#fff", //button text white instead of black
    },
    success: {
      main: "#48cf94",
      contrastText: "#fff", //button text white instead of black
    },
  },

  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 200,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 150,
      // recommended when something is leaving screen
      leavingScreen: 100,
    },
  },

  //Override components default style settings (without messing up lightmode/darkmode theme).
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: "#fff",
          color: "#000",
        },
      },
    },

    MuiTabPanel: {
      styleOverrides: {
        root: {
          "&.tabpanel": {
            backgroundColor: "#000",
          },
        },
      },
    },

    topBarSearch: {
      styleOverrides: {
        colorPrimary: {
          background: "#fff",
          color: "#000",
          border: "1px solid #ccc",
        },
        colorSecondary: {
          border: "1px solid #00aaff",
        },
      },
    },

    //Change background of the progess bar in the mobilestepper.
    MuiMobileStepper: {
      styleOverrides: {
        progress: {
          backgroundColor: "#fff", // Change the MobileStepper's background color
        },
      },
    },
  },
});

/**
 * DARK THEME
 */
export const officeonserver_themeDark = createTheme({
  palette: {
    mode: "dark", // 'ligt' or 'dark'
    background: {
      default: "#282C35", //"#0a1929",
    },
    primary: {
      main: "#00aaff", //Improve Blue
      contrastText: "#fff", //button text white instead of black
    },
    // secondary: {
    //   main: '#548756',
    // },
    success: {
      main: "#5effac",
      contrastText: "#fff", //button text white instead of black
    },
    error: {
      main: "#ff4a4a",
      contrastText: "#fff", //button text white instead of black
    },
  },

  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 200,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 150,
      // recommended when something is leaving screen
      leavingScreen: 100,
    },
  },

  //Override components default style settings (without messing up lightmode/darkmode theme).
  components: {
    // MUI: <Paper/> comp.
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#32373d", //#212429
        },
      },
    },
    //This is used to override the autofill background color in textfields (username, password filelds ect.).
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            webkitBoxShadow: "0 0 0 100px #32373d inset",
            webkitTextFillColor: "#00aaff",
            webkitBorderRadius: "0px",
          },
        },
      },
    },
    topBarSearch: {
      styleOverrides: {
        colorPrimary: {
          background: "#4a4a4a",
          color: "#fff",
          border: "none",
        },
        colorSecondary: {
          border: "none",
        },
      },
    },
  },
});
