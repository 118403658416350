import langObj_en from "libraries/languages/improve/LanguagesObject_en"; //English
import langObj_sv from "libraries/languages/improve/LanguagesObject_sv"; //Swedish

const lang = (langCode, string) => {
  if (langCode) {
    //If language is set to english
    if (langCode === "en") {
      return langObj_en[0][string];
      //...if swedish
    } else if (langCode === "sv") {
      return langObj_sv[0][string];
    }
    // Defult == English
  } else {
    return langObj_en[0][string];
  }
};

export default lang;
